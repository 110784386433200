// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.font-weight-bold {
    font-weight: 900;
    color: black;
}

.logo {
    max-width: 240px;
    height: auto;
}
.logo-small {
    max-width: 140px;
    height: auto;
}
.logo-big {
    max-height: 300px;
    height: auto;
}

.nav_sm_icon {
    opacity: 0.5;
    margin-bottom: 3px;
    margin-right: 3px;
}